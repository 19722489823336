/* src/pages/Wallet.css */

/* Wallet container */
.my-orders {
    background-color: var(--major-backgroundColor);
    color: var(--minor-color);
    padding: 2rem 0;
    text-align: center;
    flex: 1;
  }
  
  /* Wallet title */
  .my-orders h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .order-list{
    background-color: var(--major-backgroundColor);
    max-height: 410px;
    overflow-y: auto;
    padding: 0.5rem;
    border: solid 1px var(--main-borderColor);
    border-radius: 5px;
  }
  
  
  /* Transaction history section */
  .wallet-order-list {
    background-color: var(--major-color);
    color: white;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    width: 480px;

  }
  
  /* Transaction history title */
  .wallet-order-list  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  /* Transaction list */
  .wallet-order-list  ul {
    list-style: none;
    padding: 0;
  }
  
  /* Individual transaction item */
  .wallet-order-list  li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  