/* src/pages/Home.css */

/* Home container */
.home {
    background-color: var(--major-backgroundColor);
    color: var(--minor-color);
    padding: 2rem 0;
    text-align: center;
    flex: 1;
  }
  
  /* Container for centering content */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home .container{
    align-items: normal;
    max-width: 900px;
  }
  /* Heading style */
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  h2 {
    color: var(--minor-color);
    text-align: left;
    margin-left: 0px;
  }
  /* Paragraph style */
  p {
    text-align:justify;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  