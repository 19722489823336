/* PairInfo.css */

/* Container for PairInfo */
.pair-info {
  background-color: var(--major-color);
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  width: 100%;
  padding-bottom: 10px;
}

/* Title for PairInfo */
.pair-info h2 {
  text-align: center;
  margin: 15px;
}

.pair-info-parameter{
  padding: 5px 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
}